<template>
  <div class="textmessage">
    <div @click="fhubtn" class="textmessage_top">
      <span>
        <i class="el-icon-arrow-left"></i> 返回&nbsp;/
      </span>
      <span>银联企业网银支付</span>
    </div>

    <div class="textmessage_bottom">
      <div class="textmessage_bottom_text">
        <p>银联企业网银支付</p>
        <span>银联在线支付B2B支付平台以商业银行网银为基础，为B2B电子商务提供全面的网上支付及清算对账服务。</span>
      </div>

      <div class="textmessage_bottom_input">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="200px"
          class="demo-ruleForm"
        >
          <el-form-item label="商户号:" >
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_merchant_key']"
            ></el-input>
           <p>银联新网关分配的商户号</p>
          </el-form-item>

          <el-form-item label="终端号:" class="textmessage_bottom_3" >
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_client_key']"
            ></el-input>
          </el-form-item>

          <el-form-item label="appid:">
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_app_id']"
            ></el-input>
            <p>银联新网关分配的appid</p>
          </el-form-item>

          <el-form-item class="textmessage_bottom_3" label="appkey:">
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_app_key']"
            ></el-input>
            <p>银联新网关分配的appkey</p>
          </el-form-item>

          <el-form-item class="textmessage_bottom_3" label="系统编号:">
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_system_code']"
            ></el-input>
            <p>银联新网关分配的四位系统编码</p>
          </el-form-item>

          <el-form-item class="textmessage_bottom_3" label="请求地址:">
            <el-input
              clearable
              style="width:355px;"
              v-model.trim="ruleForm['pay.union.btb_request_url']"
            ></el-input>
            <p>api请求正式环境地址</p>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="addtion_foot" v-if="hasPerm(['setting.union_pay_btb.update'])">
      <div class="addtion_foot-body">
        <div class="goodsbtn">取消</div>
        <div @click="edit" class="goodsbtn">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {},
      detalform: 1,
      radio: "1",
      ruleForm: {
        name: "",
        KeySecret: "",
        stencils: ""
      },
      rules: {
        "sms.aliyun.access_key_id": [
          {
            required: true,
            message: "请输入阿里云AccessKeyId",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.access_key_secret": [
          {
            required: true,
            message: "请输入阿里云AccessKeySecret",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.sign_name": [
          { required: true, message: "请输入模板签名", trigger: "blur" }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ],
        "sms.aliyun.order_create.template_name": [
          {
            required: true,
            message: "请输入模板名称",
            trigger: "blur"
          }
          // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
        ]
      }
    };
  },
  created(){
     this.mentinit()
  },
  methods: {
     mentinit(){
      this.$get(this.$apis.newyliaw).then(res=>{
       if(res.code==200){
        this.ruleForm = res.data
       }else{
        this.$message.error("接口请求失败");
       }
      })
    },
    edit(){
         this.$put(this.$apis.newyliaw,this.ruleForm).then(res=>{
       if(res.code==200){
        this.$message.success("修改成功");
        this.mentinit()
       }else{
        this.$message.error("接口请求失败");
       }
      })
    },
    fhubtn() {
      this.$router.push("/setup/payment");
    }
  }
};
</script>

<style lang="less" scoped>
.textmessage {
  .textmessage_top {
    margin-bottom: 10px;
    border-radius: 4px;
    background: #fff;
    padding: 15px;
    box-sizing: border-box;
    span:first-child {
      margin-left: 5px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
    span:last-child {
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      cursor: pointer;
    }
  }
  .textmessage_bottom {
    border-radius: 4px;
    background: #fff;
    padding: 32px 32px 118px 56px;
    box-sizing: border-box;
    .textmessage_bottom_text {
      p {
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 8px;
      }
      span {
        font-size: 14px;
        font-weight: 400;
        color: #999999;
      }
    }
    .textmessage_bottom_input {
      margin-top: 40px;
      margin-left: -122px;
      p {
        position: absolute;
        top: 34px;
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
      .textmessage_bottom_3 {
        margin-top: 50px;
      }
    }
  }
  .addtion_foot {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    padding-left: 160px;
    box-sizing: border-box;
    height: auto;
    border-top: 1px solid #f1f1f1;
    z-index: 6;
    background-color: #fff;
    .addtion_foot-body {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px !important;
      .goodsbtn:first-of-type {
        background: #ffffff;
        color: #666666;
        padding: 0 16px;
        width: 88px;
        height: 38px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 38px;
        cursor: pointer;
        display: inline-block;
        text-align: center;
        border: 1px solid #ededed;
      }
      .goodsbtn {
        background: var(--fontColor);
        color: #fff;
        padding: 0 16px;
        width: 88px;
        height: 38px;
        font-size: 14px;
        border-radius: 4px;
        line-height: 38px;
        cursor: pointer;
        display: inline-block;
        border: none;
        border-color: transparent;
        text-align: center;
        margin-left: 8px;
      }
    }
  }
}
</style>